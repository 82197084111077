import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Router, Route } from "@reach/router"

import Layout from "../components/layout"
import Profile from "./partner/profile"
import Submit from "./partner/submit"
import Dashboard from "./partner/dashboard"
import Admin from "./partner/admin"
import AdminRoomsPage from "./partner/admin/rooms"
import TagsEditor from "./partner/admin/tags"
import TagProjects from "./partner/admin/tag-projects"
import ApproveProjects from "./partner/admin/approval"
import FeatureProjects from "./partner/admin/feature"
import MigrateProjects from "././partner/admin/migrate.js"

const PartnerPage = props => {
  return (
    <div>
      <Router basepath="/partner">
        <Profile exact path="/profile" />
        <Submit
          exact
          path="/submit"
          addMarker={props.addMarker}
          flyTo={props.flyTo}
          flyToZoom={props.flyToZoom}
          addDraggableMarker={props.addDraggableMarker}
          handleDraggableMarkerUpdate={props.handleDraggableMarkerUpdate}
        />
        <Dashboard
          exact
          path="/"
          addMarker={props.addMarker}
          flyTo={props.flyTo}
          flyToZoom={props.flyToZoom}
          addDraggableMarker={props.addDraggableMarker}
        />
        <Admin exact path="/admin" />
        <TagsEditor path="/admin/tags/editor" />
        <TagProjects path="/admin/tags/projects" />
        <ApproveProjects exact path="admin/approve" />
        <FeatureProjects exact path="admin/feature" />
        <AdminRoomsPage exact path="/admin/rooms" />
        <MigrateProjects exact path="/admin/migrate" />
      </Router>
    </div>
  )
}

export default PartnerPage
